// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-index-js": () => import("./../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-about-us-company-profile-js": () => import("./../src/templates/about_us/CompanyProfile.js" /* webpackChunkName: "component---src-templates-about-us-company-profile-js" */),
  "component---src-templates-about-us-medical-dept-js": () => import("./../src/templates/about_us/MedicalDept.js" /* webpackChunkName: "component---src-templates-about-us-medical-dept-js" */),
  "component---src-templates-about-us-show-medical-dept-js": () => import("./../src/templates/about_us/ShowMedicalDept.js" /* webpackChunkName: "component---src-templates-about-us-show-medical-dept-js" */),
  "component---src-templates-about-us-mission-vision-js": () => import("./../src/templates/about_us/MissionVision.js" /* webpackChunkName: "component---src-templates-about-us-mission-vision-js" */),
  "component---src-templates-our-doctor-index-js": () => import("./../src/templates/our_doctor/index.js" /* webpackChunkName: "component---src-templates-our-doctor-index-js" */),
  "component---src-templates-our-doctor-show-js": () => import("./../src/templates/our_doctor/show.js" /* webpackChunkName: "component---src-templates-our-doctor-show-js" */),
  "component---src-templates-our-services-index-js": () => import("./../src/templates/our_services/index.js" /* webpackChunkName: "component---src-templates-our-services-index-js" */),
  "component---src-templates-our-services-show-js": () => import("./../src/templates/our_services/show.js" /* webpackChunkName: "component---src-templates-our-services-show-js" */),
  "component---src-templates-promos-index-js": () => import("./../src/templates/promos/index.js" /* webpackChunkName: "component---src-templates-promos-index-js" */),
  "component---src-templates-promos-show-js": () => import("./../src/templates/promos/show.js" /* webpackChunkName: "component---src-templates-promos-show-js" */),
  "component---src-templates-event-show-js": () => import("./../src/templates/event/show.js" /* webpackChunkName: "component---src-templates-event-show-js" */),
  "component---src-templates-our-suites-index-js": () => import("./../src/templates/our_suites/index.js" /* webpackChunkName: "component---src-templates-our-suites-index-js" */),
  "component---src-templates-our-suites-show-js": () => import("./../src/templates/our_suites/show.js" /* webpackChunkName: "component---src-templates-our-suites-show-js" */),
  "component---src-templates-careers-index-js": () => import("./../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-careers-show-js": () => import("./../src/templates/careers/show.js" /* webpackChunkName: "component---src-templates-careers-show-js" */),
  "component---src-templates-contact-index-js": () => import("./../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

